import React from "react"
import Layout from "../../components/Layout";
import Footer from '../../components/Footer';
import Netlise from "../../modules/clients/Netlise";

const NetlisePage = () => {

  return (
    <Layout>
      <Netlise />
      <Footer />
    </Layout>
  )
}

export default NetlisePage;